import React from 'react';
import amplitude from 'amplitude-js';
import '../Form.scss';

class formSuccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			imageDesktop: '',
            selectedModelId: '',
            campaign: ''
		}
	}

	componentDidMount() {
        let event = "Thank you - form completed";
        let eventProperties = {
            "form": "lottery",
            "country": this.props.match.params.country,
            "language": this.props.match.params.lang
        };

        amplitude.getInstance().logEvent(event, eventProperties);

		this.props.sidebarSetup(0, false);
		this.updateWindowDimensions();
		window.addEventListener("resize", this.updateWindowDimensions);
	}

	componentWillUnmount() {
		localStorage.clear();
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({width: window.innerWidth});

		if (window.innerWidth < 769) {
			this.setState({
				imageDesktop: false
			})
		} else {
			this.setState({
				imageDesktop: true
			})
		}
	};

	render() {
		let translate = this.props.translate;
		let settings = this.props.settings;

		return (
			<div className="form__success">
				<div className="form__sidebar"
						 style={{backgroundImage: `url(${this.state.imageDesktop ? settings.image_lottery_desktop : settings.image_lottery_mobile})`}}>
                    <div className="overlay"></div>
                </div>

				<div className="form__content">
					<div>
						<h2 className={'aui-headline-2 success-'+this.state.campaign}>{translate.success_title_lottery}</h2>
						<h4 className="aui-headline-4">{translate.success_subtitle_lottery}</h4>
					</div>
				</div>
			</div>
		)
	}
}

export default formSuccess;
