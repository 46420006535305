import React from 'react';
import {Textfield} from '@audi/audi-ui'
import {Checkbox} from "@audi/audi-ui/src";
import {NavLink} from "react-router-dom";
import axios from "axios";
import amplitude from 'amplitude-js';
import '../Form.scss';

class formUserInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: 'lottery',
			editLink: '?edit',
			callback: '1',
			name: '',
			phone: '',
			email: '',
            dealer: '',

            selectedValue: 'not-selected',
			selectedName: '',

			nameError: false,
			phoneError: false,
			emailError: false,
            dealerError: false,

			formValid: false,
			phoneFormValid: false,
			nameValid: false,
			emailValid: false,
			phoneValid: false,
            dealerValid: false,
            contactValid: false,

            allowContact: false,
			allowInform: false,
			allowContactError: false,

			formStep: 1,
            disabled: false
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
        amplitude.getInstance().init("849d00a092bb01538ad10fb5ddcd75d5");

        let event = "Form opened";
        let eventProperties = {
            "form": "lottery",
            "country": this.props.match.params.country,
            "language": this.props.match.params.lang
        };

        amplitude.getInstance().logEvent(event, eventProperties);

		this.props.sidebarSetup(this.state.formStep, true);

        Checkbox.upgradeElements();
        Textfield.upgradeElements();
	}

	handleChange = name => event => {
        if (name === 'dealer') {
            this.setState({
    			selectedValue: event.target.value,
    			selectedName: event.target.options[event.target.selectedIndex].text
    		});

            if (event.target.value !== 'not-selected') {
    			this.setState({dealerError: false})
    		}

            this.validateField(name, event.target.value);
        } else if (name === 'allowContact') {
    		this.setState({
    			allowContact: !this.state.allowContact,
    		})
        } else {
            this.setState({
    			[name]: event.target.value
    		});

            this.validateField(name, event.target.value);
        }
	};

	validateField(fieldName, value) {
		let nameValid = this.state.nameValid;
		let phoneValid = this.state.phoneValid;
		let emailValid = this.state.emailValid;
        let dealerValid = this.state.dealerValid;

		switch (fieldName) {
			case 'name':
				nameValid = value.length >= 2;
				break;
			case 'phone':
				phoneValid = value.match(/^.\d{7,12}$/) ? true : false;
				break;
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
            case 'dealer':
                dealerValid = value !== 'not-selected';
                break;
			default:
				break;
		}

		this.setState({
			nameValid: nameValid,
			phoneValid: phoneValid,
			emailValid: emailValid,
            dealerValid: dealerValid
		}, this.validateForm);
	}


	validateForm() {
		this.setState({
			formValid: this.state.nameValid &&
                this.state.emailValid &&
                this.state.phoneValid &&
                this.state.dealerValid
		})
	}

	handleSubmit = event => {
		event.preventDefault();

		// Validate fields
		if (!this.state.formValid) {

			this.setState({
				nameError: !this.state.nameValid,
				phoneError: !this.state.phoneValid,
				emailError: !this.state.emailValid,
                dealerError: !this.state.dealerValid,
			})

		} else {
            if (this.state.disabled) {
                return;
            }

            this.setState({disabled: true});

            if (this.state.allowContact) {
        		axios
        			.post(window.API + '/' + this.props.match.params.lang + '/api/submit/' + this.props.match.params.country, {
        				form: this.state.form,
        				name: this.state.name,
        				phone: this.state.phone,
        				email: this.state.email,
        				dealer: this.state.selectedValue,
        				message: '',
        				token: localStorage.getItem('token'),
        				page: localStorage.getItem('page'),
                        campaign: 'lottery',
        				allow_contact: this.state.allowContact,
        				allow_inform: this.state.allowInform,
        				callback: 0
        			})
        			.then(function (response) {
                        //
        			})
        			.catch(function (error) {
        				console.log(error);
        			});

                    window.parent.postMessage('scroll', '*');
                    this.props.history.push('loterija-success')
            } else {
                this.setState({allowContactError: true, disabled: false})
        	}
		}
	}

	render() {
		const translate = this.props.translate;
        let dealers = this.props.dealers;

		return (

			<div className="form__content min-padding">
				<div>
					<h4 className="aui-headline-4">{translate.user_info_title_lottery}</h4>
                    <p className="aui-shortread">{translate.user_info_text_lottery}</p>
					<form>
						<div className="aui-textfield aui-js-textfield aui-textfield--floating-label">
							<div className="aui-textfield__field">
								<input className="aui-textfield__input"
											 type="text"
											 name="name"
											 value={this.state.name}
											 onChange={this.handleChange('name')}/>
								<label className="aui-textfield__label" htmlFor="name">{translate.user_info_name_lottery}</label>
							</div>
							<span className={'aui-textfield__error ' + (this.state.nameError ? 'visible' : '')}>
								{translate.user_info_name_error}
							</span>
						</div>

						<div className="cols">

							<div className="aui-textfield aui-js-textfield aui-textfield--floating-label">
								<div className="aui-textfield__field">
									<input className="aui-textfield__input"
												 type="text"
												 name="phone"
												 value={this.state.phone}
												 onChange={this.handleChange('phone')}/>
									<label className="aui-textfield__label" htmlFor="phone">{translate.user_info_phone_lottery}</label>
								</div>
								<span className={'aui-textfield__error ' + (this.state.phoneError ? 'visible' : '')}>
									{translate.user_info_phone_error}
								</span>
							</div>

							<div className="col-helper">
								&nbsp;
							</div>

							<div className="aui-textfield aui-js-textfield aui-textfield--floating-label">
								<div className="aui-textfield__field">
									<input className="aui-textfield__input"
												 type="text"
												 name="email"
												 value={this.state.email}
												 onChange={this.handleChange('email')}/>
									<label className="aui-textfield__label" htmlFor="email">{translate.user_info_email_lottery}</label>
								</div>
								<span className={'aui-textfield__error ' + (this.state.emailError ? 'visible' : '')}>
									{translate.user_info_email_error}
								</span>
							</div>

						</div>

                        <div className="aui-select aui-js-select aui-js-response aui-select--floating-label aui-select--floating-label-no-padding-top">
							<select value={this.state.selectedValue} className="aui-select__input" id="choose-dealer"
                                            onChange={this.handleChange('dealer')}>

								<option className="aui-select__input-label"
												defaultValue="not-selected"
												value="not-selected">
									{translate.dealers_choose_dealer_lottery}
								</option>

                                {dealers.map((dealer, index) => {
                                    if (dealer.id !== '826')
  									    return <option key={index} value={dealer.id}>{dealer.title}</option>
                                })}
							</select>
							<label className="aui-select__label" htmlFor="choose-dealer">{translate.dealers_choose_dealer_lottery}</label>
							<span className={'aui-textfield__error ' + (this.state.dealerError ? 'visible' : '')}>
								{translate.dealers_error_not_selected}
							</span>
						</div>

                        <div className="checkbox__wrap">
    						<label className={'aui-checkbox aui-js-checkbox ' + (this.state.allowContactError ? 'error' : '')} >
    							<input className="aui-checkbox__input"
    										 type="checkbox"
    										 name="allowContact"
    										 value={this.state.allowContact}
    										 onChange={this.handleChange('allowContact')}/>
    							<span  className="aui-checkbox__label aui-small"
    										 dangerouslySetInnerHTML={{__html: translate.summary_allow_contact_lottery}}>
    							</span>
    						</label>

    						<label className="aui-checkbox aui-js-checkbox">
    							<input className="aui-checkbox__input"
    										 type="checkbox"
    										 name="allowInform"
    										 value={this.state.allowInform}
    										 onChange={this.handleChange('allowInform')}/>
    							<span className="aui-checkbox__label aui-small">{translate.summary_allow_inform_lottery}</span>
    						</label>
    					</div>

						<div className="buttons-wrap">
							<button className="aui-button aui-button--primary aui-js-response"
											type="submit"
                                            disabled={this.state.disabled}
											onClick={this.handleSubmit}>
								{translate.user_info_submit_lottery}
							</button>
						</div>

                        <p className="aui-small">{translate.user_info_bottom_lottery}</p>
					</form>
				</div>
			</div>

		)
	}
}

export default formUserInformation;
