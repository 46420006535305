import React from 'react';
import '../Form.scss';

class formSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			imageDesktop: '',
			receivedDesktopImage: '',
			receivedMobileImage: ''
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener("resize", this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth});

		if(window.innerWidth < 769){
			this.setState({
				imageDesktop: false
			})
		}else{
			this.setState({
				imageDesktop: true
			})
		}
	};

	componentWillReceiveProps(nextProps) {

		if(nextProps.settings.image_lottery_desktop !== this.state.receivedDesktopImage){
			this.setState({
				receivedDesktopImage: nextProps.settings.image_lottery_desktop
			})
		}

		if(nextProps.settings.image_lottery_mobile !== this.state.receivedMobileImage){
			this.setState({
				receivedMobileImage: nextProps.settings.image_lottery_mobile
			})
		}
	}

	render() {
		return(
			<div className="form__sidebar"
					 style={{backgroundImage: `url(${this.state.imageDesktop ? this.state.receivedDesktopImage : this.state.receivedMobileImage })`}}>
				<div className="overlay"></div>
			</div>
		)
	}
}

export default formSidebar;
